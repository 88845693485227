<template>
    <div v-loading.fullscreen.lock="loading">
        <div class="header-section">
            <div class="login-logo mb-4">
                <img class="logo_img" src="/assets/images/logo-img.png"/>
            </div>
            <div class="toplogin-btn">
                <router-link class="base-button btn btn-default login-text px-2 signup-text" to="/login">Login</router-link>
                <router-link class="base-button btn btn-default login-text mr-2 px-2 signup-text" to="/signup">Sign up</router-link>
            </div>

        </div>
        <section class="banner-section">
            <div class="container">
                <div class="banner-caption">
                    <h2>DRA Training Platform</h2>
                    <!--          <p>-->
                    <!--           Training solutions to become a DRA Certified Employee or comply with California’s <br/> Sexual Harassment Prevention Training requirements-->
                    <!--          </p>-->
                    <p>Industry training solutions to fit the needs of current or future restaurant employees</p>
                </div>
            </div>
        </section>
        <!--Banner Section--->
        <div class="content-area">
            <div class="container">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                        <h2 class="text-center mb-5 mt-4">
                            Forgot Password
                        </h2>
                        <p class="mb-4 mt-3">
                            Your email address is usually your username for the platform.
                            Please enter your email address below and a “Reset Password” link
                            will be sent to the email we have on file. If you do not have an
                            email address in the system, please email us at:
                            <a :href="'mailto:' + infoEmail">{{ infoEmail }}</a
                            >.
                        </p>
                        <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                            <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                                <base-input
                                    alternative
                                    class="mb-3 login-input"
                                    name="Email"
                                    :rules="{ required: true }"
                                    prepend-icon="ni ni-email-83"
                                    placeholder="Email"
                                    v-model="form.email"
                                >
                                </base-input>

                                <div class="text-center mb-2">
                                    <base-button native-type="submit" class="custom-btn"
                                    >Submit
                                    </base-button
                                    >
                                </div>
                            </form>
                            <p class="error" v-if="errors.invalid">{{ errors.invalid }}</p>
                        </validation-observer>
                    </div>
                    <div class="col-md-3"></div>
                </div>
            </div>
        </div>
        <the-footer/>
    </div>
</template>
<script>
import TheFooter from '@/views/Layout/AppFooter.vue'
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import {Dynamic} from "../../wl";

export default {
    components: { TheFooter},
    data() {
        return {
            loading: false,
            form: {
                email: ""
            },
            errors: {
                invalid: ""
            },
            infoEmail: ""
        };
    },
    created: function () {
        this.infoEmail = Dynamic.INFO_EMAIL;
    },
    methods: {
        onSubmit() {
            this.loading = true;
            this.$http
                .post("user/resetLink", {
                    user_name: this.form.email
                })
                .then(resp => {
                    Swal.fire({
                        html: resp.data.message,
                        icon: "success",
                        confirmButtonClass: "btn btn-success btn-fill"
                    });
                })
                .catch(function (error) {
                    Swal.fire({
                        title: "Error!",
                        html: error.response.data.message,
                        icon: "error"
                    });
                })
                .finally(() => (this.loading = false));
        }
    }
};
</script>
<style>
body,
html {
    height: 100%;
}

.error {
    color: red;
    text-align: center;
}

.py-5 {
    padding-bottom: 0px !important;
}

.mt--10 {
    margin-top: -10rem !important;
}

.ctm-btn {
    background-color: #a8312d;
    color: #ffffff;
    border-radius: 0 !important;
    font-size: 17px;
    margin-right: 5px;
}

.login-inputs {
    padding-top: 20px;
}

.login {
    margin-right: 0px !important;
}


.header-section {
    display: flex;
    justify-content: space-between;
}

.logo_img {
    width: 100px;
    padding: 10px;
}

.toplogin-btn {
    display: flex;
    width: 50%;
    justify-content: end;
    flex-direction: row-reverse;
}

a.signup-text {
    font-weight: 700;
}

.toplogin-btn a {
    height: 39px;
}

.login-text {
    right: 25px;
}

@media (max-width: 576px) {
    .header-section {
        min-height: 100px !important;
        height: 100px;
    }

}
</style>
